import Emitter from '../utilities/Emitter.js';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default class Popup {

    constructor(options) {
        this.$el = options.$el;
        
        this.$login = this.$el.querySelector('.js-popup-login');
        this.$content = this.$el.querySelector('.js-popup-content');
        this.$close = this.$el.querySelector('.js-popup-close');
        this.current = "";

        this.createDynamicRegisterIframe();
        this.addEvent();

        if( window.location.href.indexOf("?ref=") > -1){
            Emitter.emit('showPopup', 'register')
        }
    }

    addEvent() {
        Emitter.on('showPopup', this.showPopup.bind(this));
        this.$close.addEventListener('click', this.onClose.bind(this))
    }

    createDynamicRegisterIframe(){
        var url = encodeURIComponent(document.location.href);

        var ifrm = document.createElement("iframe");
        if( window.location.href.indexOf("?ref=") > -1){
            ifrm.setAttribute("src", `https://distro.tiememusic.com/registration_iframe.php?ref=${url}`);
        }else{
            ifrm.setAttribute("src", `https://distro.tiememusic.com/registration_iframe.php`);
        }
            
        ifrm.setAttribute("title", "Signup");
        ifrm.setAttribute("class", "js-popup-register");
        ifrm.setAttribute("style", "width:100%; height:541px; border:none;");
        this.$content.appendChild(ifrm);
        this.$register = this.$el.querySelector('.js-popup-register');
    }

    showPopup(elm){
        this.current = elm;
        
        disableBodyScroll(this.$content)
        this.$el.classList.add('is-active');

        this.$content.classList.add(this.current);
        if( this.current === "login"){
            this.$login.style.display = "block";
            this.$register.style.display = "none";
        }

        if( this.current === "register"){
            this.$login.style.display = "none";
            this.$register.style.display = "block";
        }
    }

    onClose(){
        this.$el.classList.remove('is-active');
        this.$content.classList.remove(this.current);
        clearAllBodyScrollLocks();
    }

}