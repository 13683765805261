import Emitter from '../utilities/Emitter.js';

export default class Login {

    constructor(options) {
        this.$el = options.$el;
        this.popup = this.$el.getAttribute('data-popup');
        this.addEvent();
    }

    addEvent() {
        this.$el.addEventListener('click', this.onClick.bind(this))
    }

    onClick(){
        Emitter.emit('showPopup', this.popup)
    }
}