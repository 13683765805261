import '../styles/main.scss';

import Window from './stores/Window.js';
import Emitter from './utilities/Emitter.js';
import Marquee from './components/Marquee.js';
import Rotate from './components/Rotate.js';
import Popup from './components/Popup.js';
import TriggerPopup from './components/TriggerPopup.js';


const components = [
    { el: '.js-marquee', component: Marquee },
    { el: '.js-rotate', component: Rotate },
    { el: '.js-popup-btn', component: TriggerPopup },
    { el: '.js-popup', component: Popup },
];



class App {
    constructor() {
        this._components = [];

        window.addEventListener('resize', this.resize, false);
        window.addEventListener('scroll', this.scroll, false);

        this._update = this.update.bind(this);
        
        this.update();
        this.createComponents(components);
    }

    createComponents(components) {
        for (let i = 0, l = components.length; i < l; i++) {
            let elements = document.querySelectorAll(components[i].el);

            Array.prototype.forEach.call(elements, (el) => {
                let params = { $el: el };
                if (!!components[i].params) Object.assign(params, components[i].params);

                const view = new components[i].component(params);

                this._components.push(view);
            });
        }
    }

    update() {
        Emitter.emit('update');
        requestAnimationFrame(this._update);
    }

    resize() {
        Emitter.emit('resize');
    }

    scroll() {
        if (Window.scrollEnable) {
            Emitter.emit('scroll');
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});