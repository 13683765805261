import Emitter from '../utilities/Emitter.js';
import Window from '../stores/Window.js';

export default class Marque {

    constructor(options) {
        this.$el = options.$el;
        
        this.translate = {
            current: 0,
            previous: 0
        }
        this.progress = 0;
        this.active = false;
        this.onResize();
        this.addEvent();
    }

    lerp (a, b, n){ 
        return a + (b - a) * n
    }

    addEvent() {
        Emitter.on('scroll', this.onScroll.bind(this));
        Emitter.on('update', this.update.bind(this));
        Emitter.on('update', this.onResize.bind(this));
    }

    onResize(){
        if(Window.width <= 767){
            this.$el.style.transform = `none`;
            this.active = false;
        }else{
            this.active = true;
        }
    }

    update(){
        this.translate.previous = this.translate.current;
        this.translate.current = this.lerp(this.progress, this.translate.current, 0.9);
        this.$el.style.transform = `rotate(${8-this.translate.current}deg)`
    }

    onScroll(){
        this.progress = Window.scroll /100
    }
}