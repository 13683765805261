import Emitter from '../utilities/Emitter.js';

class Window {

    constructor() {
        this.width = null;
        this.height = null;

        this.scrollEnable = true;

        this.scroll = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

        this.resize();

        // Listen global events
        window.addEventListener('scroll', this.onScroll.bind(this), false);
        Emitter.on('resize', this.resize.bind(this));
    }

    resize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }

    onScroll() {
        this.scroll = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
    }
}

const dataWindow = new Window;

export default dataWindow;