import Emitter from '../utilities/Emitter.js';
import Window from '../stores/Window.js';

export default class Marque {

    constructor(options) {
        this.addEvent();
        this.$el = options.$el;
        this.bounding = this.$el.getBoundingClientRect();
        this.marquees = [...this.$el.querySelectorAll('.js-line')];
        this.marquesChild = []
        this.marquees.forEach(marquee => { 
            this.marquesChild.push([...marquee.querySelectorAll('img')]);
        })
        this.translate = {
            current: 0,
            previous: 0
        }
        this.progress = 0;
    }

    onResize(){
        this.bounding = this.$el.getBoundingClientRect();
    }

    lerp (a, b, n){ 
        return a + (b - a) * n
    }

    addEvent() {
        Emitter.on('scroll', this.onScroll.bind(this));
        Emitter.on('resize', this.onResize.bind(this));
        Emitter.on('update', this.update.bind(this));
    }

    update(){
        this.translate.previous = this.translate.current;
        // console.log(this.marquees);
        this.translate.current = this.lerp(this.progress, this.translate.current, 0.9);
        for (let i = 0; i < this.marquees.length; i++) {
            let transform = this.translate.current * (1-(1/(i+2)));
            this.marquees[i].style.transform = `translateX(${-transform }px)`

            for (let j = 0; j < this.marquesChild[i].length; j++) {
                const offset = Window.width < 1100 ? 0.4 : 0.3;
                const itemPos = (j+offset)*(360+40) - transform
                this.marquesChild[i][j].style.opacity = (itemPos / this.bounding.width) *0.7;
            }
            this.marquesChild
        }
    }

    onScroll(){
        this.progress = Window.scroll /2
    }
}